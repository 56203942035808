<template>
	<div :class="['spline', type ? `spline--${type}` : '']">
		<div class="spline__canvas">
			<canvas v-if="typeOfFile === '3d'" ref="container"></canvas>

            <template v-else-if="typeOfFile === 'video' && url">
                <Video
                    v-if="!urlMobile || !viewport.isLessThan('tablet')"
                    class="video--spline video--bg"
                    :src="url"
                    :playsinline="true"
                    :autoplay="true"
                    :muted="true"
                    :loop="true"
                    :width="330"
                    :height="330"
                />
                <Video
                    v-else-if="urlMobile && viewport.isLessThan('tablet')"
                    class="video--spline video--bg"
                    :src="urlMobile"
                    :playsinline="true"
                    :autoplay="true"
                    :muted="true"
                    :loop="true"
                    :width="334"
                    :height="640"
                />
            </template>
		</div>
	</div>
</template>


<script setup lang="ts">
import * as Three from 'three';
import { Scene, Vector3 } from 'three';
import { GLTFLoader } from 'three/addons/loaders/GLTFLoader.js';
import { OrbitControls } from 'three/addons/controls/OrbitControls.js';
import Video from '~/components/ui/Video.vue';

const props = defineProps({
	url: { type: String, readonly: true },
    urlMobile: { type: String, readonly: true },
	// video: { type: String, readonly: true },
	type: { type: String, readonly: true },
    isMobile: { type: Boolean, readonly: true },
});

const container = ref<HTMLCanvasElement | null>(null);
const camera = ref();
const renderer = ref();
const scene = new Three.Scene();
const loader = new GLTFLoader();
const viewport = useViewport();

function getSizes(scene: Scene) {
	const box = new Three.Box3().setFromObject(scene);
	return {
		size: box.getSize(new Three.Vector3()).length(),
		center: box.getCenter(new Three.Vector3())
	}
}

function setPosition(scene: Scene, center: Vector3): void {
	scene.position.x += scene.position.x - center.x;
	scene.position.y += scene.position.y - center.y;
	scene.position.z += scene.position.z - center.z;
}

function setCameraPosition(size: number, center: Vector3): void {
	camera.value.near = size / 100;
	camera.value.far = size * 100;
	camera.value.updateProjectionMatrix();
	camera.value.position.copy(center);
	camera.value.position.x += size / 1.0;
	camera.value.position.y += size / 2.5;
	camera.value.position.z += size / 1.0;
	camera.value.lookAt(center);
}

function setControls(size: number): void {
	const controls = new OrbitControls(camera.value, renderer.value.domElement);
	controls.maxDistance = size * 10;
	controls.enableDamping = true;
	controls.enablePan = true;
	controls.target.set(0, 0, 0);
	controls.update();
}

const typeOfFile = computed(() => {
	if (props.url?.indexOf('mp4') !== -1) {
		return 'video'
	} else {
		return '3d'
	}
})

onMounted(() => {
	if (container.value && props.url) {
		const width = container.value.clientWidth;
		const height = container.value.clientHeight;

		renderer.value = new Three.WebGLRenderer({
			canvas: container.value,
			antialias: true,
			alpha: true,
			// autoClear: true,
			// physicallyCorrectLights: true,
		});

		renderer.value.autoClear = true;
		renderer.value.physicallyCorrectLights = true;
		renderer.value.setPixelRatio( window.devicePixelRatio );
		renderer.value.shadowMap.enabled = true;

		renderer.value.setSize(width, height);

		loader.load(props.url, function (gltf: any) {
			gltf.scene.updateMatrixWorld();
			const fov = 60;
			const aspect = width / height;
			camera.value = new Three.PerspectiveCamera(fov, aspect, 0.01, 1000);

			const sizes = getSizes(gltf.scene);

			setPosition(gltf.scene, sizes.center);
			setCameraPosition(sizes.size, sizes.center);
			setControls(sizes.size);

			gltf.scene.visible = true;

			const mixer = new Three.AnimationMixer(gltf.scene);
			if (gltf.animations.length) {
				const action = mixer.clipAction(gltf.animations[0]);
				action.play();
				action.loop = Three.LoopPingPong;
			}

			const clock = new Three.Clock();
			function renderLoop() {
				requestAnimationFrame(renderLoop);
				mixer.update(clock.getDelta());
				render();
			}

			renderLoop();

			if (gltf.scene.background) {
				scene.background = new Three.Color(gltf.scene.background);
			}

			const pointLight = new Three.PointLight();
			const ambientLight = new Three.AmbientLight('#ffffff', 0.3);
			const directionalLight = new Three.DirectionalLight('#ffffff', 2.1);

			// let matcapTexture = new Three.TextureLoader().load('splines/company/texture.png')
			// const material = new Three.MeshMatcapMaterial();

			gltf.scene.traverse(function(child: any) {
				if ((child as Three.Mesh).isMesh) {
					// const m = (child as Three.Mesh);
					// material.matcap = matcapTexture;

					// if (m.name !== 'Base') {
					// 	m.material = material;
					// } else {
					// 	const matcapTexture = new Three.TextureLoader().load('splines/dev/Textures/Base.png')
					// 	const material = new Three.MeshMatcapMaterial();
					// 	material.matcap = matcapTexture;
					// 	m.material = material;
					// }
				}

				if ((child as Three.PointLight).isPointLight) {
					pointLight.copy(child);
				}

				if ((child as Three.AmbientLight).isAmbientLight) {
					ambientLight.copy(child);
				}

				if ((child as Three.DirectionalLight).isDirectionalLight) {
					directionalLight.copy(child);
				}
			});

			scene.add(pointLight);
			scene.add(ambientLight);
			scene.add(directionalLight);
			scene.add(gltf.scene);
			render();
		},
		function(xhr: ProgressEvent) {
			console.log( ( xhr.loaded / xhr.total * 100 ) + '% loaded' );
		},
		function(error: any): void {
			console.log( 'An error happened: ', error );
		});

		function render() {
			renderer.value.render(scene, camera.value);
		}
	}
});

defineOptions({ name: 'Spline' });

// if (props.url) {
// spline.value = new Application(canvas.value);
// spline.value
// 	.load(
//         props.url,
// 	    //   undefined,
// 	    // {
// 	    //   credentials: 'include',
// 	    //       mode: 'no-cors',
// 	    // }
//     )
// 	.then(
// 		() => console.log('spline', spline)
// 	);
// }

// onUnmounted(() => {
// console.log('unmounted', window.THREE)
// spline.value = null;
// });
</script>

<style lang="scss" src="~/assets/scss/components/ui/spline.scss" scoped></style>
